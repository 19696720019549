import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import {
  Parallax,
  ParallaxBanner,
  ParallaxProvider
} from 'react-scroll-parallax';
import { LinearProgress, Button } from '@material-ui/core';

import { getCompany } from '../../api/companyDataApi';
import { round } from '../../api/offerCalc';
import { scrollToElementInPage } from '../../api/dataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import Phone from './Phone.js';

import '../CompanyProfile/index.css';
import './index.css';

function StaticContent({ siteConfig, webSocket, actions }) {
  return (
    <div className={'company-page initted resident-email-process'}>
      <div className="section platform-intro">
        <div className="section-content">
          <div className="hero-content">
            <h1>
              Timeline of{' '}
              <img src={withPrefix(siteConfig.logo)} className="logo" />{' '}
              Communications with the New Residents
            </h1>

            <p>
              No one likes excessive messaging, but too few might not get the
              job done. MovingIN has found that the 4-stage process below works
              best. The initial ‘Welcome to your new home’ message is sent
              shortly after lease approval to introduce your new resident to the
              MovingIN dashboard. The next three messages only go out if the
              resident has failed to complete certain task before their
              scheduled send date.
            </p>

            <ul>
              <li className="resident">
                <Button>
                  <div className="image">
                    <h3 className="days-before-move-in-date">30</h3>
                  </div>
                  Sent Upon Lease Approval
                </Button>

                <Phone
                  imageSrc="/images/misc/camelback_square_for_email.png"
                  emailContent={
                    <p className="email">
                      <span className="greeting">
                        Hello Bud, <br />
                        Welcome to your new home!
                        <br />
                      </span>
                      To make your up-coming move-in experience as smooth as
                      possible we’ve created a customized MovingIN dashboard to
                      guide you through the entire process, step-by-step, in the
                      most efficient and least stressful way possible. You’ll
                      need to use this dashboard to upload the following
                      required items:
                      <div className="email-bullets">
                        ● Electric Account Details
                        <br />
                        ● Gas Account Details
                        <br />● Proof of Renters Insurance
                      </div>
                      <div className="button-instruction">
                        Click the 'Go to my MovingIN Dashboard' button below to
                        get started.
                      </div>
                    </p>
                  }
                />
              </li>
              <li className="onboarding">
                <Button>
                  <div className="image">
                    <h3 className="days-before-move-in-date">14</h3>
                  </div>
                  14 Days Before Move In Date
                </Button>

                <Phone
                  imageSrc="/images/misc/dont_forget_internet.png"
                  emailContent={
                    <p className="email">
                      <span className="greeting">
                        Hello Bud, <br />
                        Don't forget to set up Internet
                        <br />
                      </span>
                      You are only two weeks away from your move to Camelback
                      Square and we noticed that you haven’t marked Internet
                      Service ‘Completed’ on your MovingIN Dashboard yet.
                      <br />
                      <br />
                      <div className="button-instruction">
                        If you haven’t setup service yet, and would like to do
                        so now, you can see a list of available plans by
                        clicking the ‘Go to my MovingIN Dashboard’ button below.
                      </div>
                    </p>
                  }
                />
              </li>
              <li className="doc">
                <Button>
                  <div className="image">
                    <h3 className="days-before-move-in-date">10</h3>
                  </div>
                  10 Days Before Move In Date
                </Button>

                <Phone
                  imageSrc="/images/misc/friendly_reminder.jpg"
                  emailContent={
                    <p className="email">
                      <span className="greeting">
                        Hello Bud, <br />
                        Just a friendly reminder...
                        <br />
                      </span>
                      You only have 10 days left until moving day and we still
                      need the following items before you can pick up your keys.
                      <div className="email-bullets">
                        ● Electric Account Number
                        <br />
                        ● Gas Account Number
                        <br />● Proof of Renters Insurance
                      </div>
                      <div className="button-instruction">
                        You can upload the required items by clicking the ‘Go to
                        my MovingIN Dashboard’ button below.
                      </div>
                    </p>
                  }
                />
              </li>

              <li className="team">
                <Button>
                  <div className="image">
                    <h3 className="days-before-move-in-date">3</h3>
                  </div>
                  3 Days Before Move In Date
                </Button>

                <Phone
                  imageSrc="/images/misc/dont_forget.png"
                  emailContent={
                    <p className="email">
                      <span className="greeting">
                        Hello Bud, <br />
                        Required Items Missing
                        <br />
                      </span>
                      Uh oh - you only have 3 days until moving day and we are
                      stilling missing important items. Per your lease we will
                      need the following items upload to your MovingIN Dashboard
                      before you can pick up your keys.
                      <div className="email-bullets">
                        ● Proof of Renters Insurance
                      </div>
                      <div className="button-instruction">
                        You can upload the required items by clicking the ‘Go to
                        my MovingIN Dashboard’ button below.
                      </div>
                    </p>
                  }
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticContent);
