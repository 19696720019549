import React from 'react';
import SEO from '../components/SEO';
import ResidentEmailProcess from '../components/ResidentEmailProcess';

export default function ({}) {
  return (
    <>
      <SEO
        title={'Resident Email Process'}
        description={
          'No one likes excessive messaging, but too few might not get the job done. MovingIN has found that this 4-stage process below works best. '
        }
        brand={'mi'}
      />

      <ResidentEmailProcess />
    </>
  );
}
