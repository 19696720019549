import * as api from './apiUtils';
import { getCookie } from './dataApi';

function getConfig(companyKey, ownerKey, profileKey) {
  let config = { headers: { user: getCookie('mi-user') } };

  if (companyKey) {
    config.headers.company = companyKey;
  } else if (ownerKey) {
    config.headers.owner = ownerKey;
  } else if (profileKey) {
    config.headers.property = profileKey;
  }

  return { ...config };
}

export function getCompany(companyKey, ownerKey, profileKey) {
  return api
    .get(
      'API/CompanyData/GetCompany',
      getConfig(companyKey, ownerKey, profileKey)
    )
    .then((response) => {
      return response.data && ownerKey
        ? { ...response.data, ManagementCompanyName: response.data.OwnerName }
        : response.data;
    });
}

export function getRoadmap(roadmapKey) {
  return api
    .get('API/Roadmap/GetRoadMap?roadmap=' + (roadmapKey ? roadmapKey : ''))
    .then((response) => {
      return response.data;
    });
}

export function registerForRoadmapEvent(roadmapKey, request) {
  return api
    .post('API/Roadmap/RegisterForEvent?roadmap=' + roadmapKey, request)
    .then((response) => {
      return response.data ? String(response.data).split('"').join('') : '';
    });
}

export function getDemoRoadmap() {
  return api.get('API/Roadmap/GetDemoRoadmap').then((response) => {
    return response.data;
  });
}
