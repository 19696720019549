import React from 'react';
import { withPrefix } from 'gatsby-link';

import './index.css';

function Phone(props) {
  return (
    <div className="email-process-phone-container">
      <img
        src={withPrefix('/images/mi-landing/mobile-screenshot.png')}
        className="phone-frame"
      />

      <div className="text-container">
        <div className="email-header">
          <div className="profile-picture">
            <div className="initials">CS</div>
          </div>
          <div className="tile-recipient">
            <p>
              Camelback Square <br />
              To:<span className="recipient"> Jennifer Wilson</span>
            </p>
          </div>
          <div className="time">10:34 AM</div>
        </div>
        <div className="email-image">
          <img src={withPrefix(props.imageSrc)} />
        </div>
        <div className="email-content">{props.emailContent}</div>
        <div className="email-footer">
          <div className="button">Go to my MovingIN Dashboard</div>
          <div className="bottom-info">
            www.movingin.com
            <br />
            2525 E Camelback Road STE 810 ● Phoenix, AZ 85016
            <br />
            <span>Unsubscribe</span> ● <span>Terms of Service</span> ●{' '}
            <span>Privacy</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Phone;
